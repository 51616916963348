import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import SEO from "../components/seo"

import Layout from "../components/layout"
import { Section, Wrapper, Paragraph } from "../components/styles/container"
import { Grid, Col } from "../components/styles/grid"
import Quote from "../components/quote"
import Mail from "../components/mail"

import Upper from "../images/training_oben_cropped.jpg"
import BackgroundLetter from "../images/icons/training.svg"
import Lower from "../images/training_unten_cropped.jpg"
import Sign from "../images/sign.png"

/******************************************************************************************* STYLED
 *************************************************************************************************/

const TransformImg = styled.img`
  /* Width in PX > 1920px */
  @media only screen and (min-width: 121em) {
    transform: translateX(40%);
  }
`

const Relative = styled.div`
  position: relative;
`

const LetterStyle = styled.img`
  max-height: 300px;
  opacity: 0.15;
  z-index: -1;
  position: absolute;
  bottom: -100px;
  right: 20px;
`

const List = styled.ul`
  margin-bottom: 3rem;
  list-style-image: url(${Sign});
  transform: translateX(13px);
  li {
    transform: translateX(15px);
  }
`

const SecondSection = styled(Section)`
  margin-top: 11rem;
  /* Width in PX > 1200px */
  /* ==== = LARGE = ==== */
  @media only screen and (min-width: 75em) {
    margin-top: 20rem;
  }
`

/******************************************************************************************** COACHING COMPONENT
 **************************************************************************************************/

const Training = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 75em)",
  })

  const isMobile = useMediaQuery({
    query: "(max-width: 74.99em)",
  })

  return (
    <Layout>
      <SEO title="Training" />
      {/* // ---------------------------------------------------------------------------FIRST SECTION ON MOBILE */}

      <Section className="appear l-disappear">
        <Wrapper className="flex">
          <Grid gap="7">
            <Col>
              <Relative>
                <h2>Training</h2>
                <h3>
                  Menschen machen Unternehmen erfolgreich und Erfolg ist nur
                  über Training erreichbar.
                </h3>
                <Paragraph>
                  Ein Profi-Sportler trainiert nahezu täglich, um an der Spitze
                  zu bleiben. Daher ist es nur logisch, Mitarbeitende regelmäßig
                  trainieren zu lassen, um ihre Kompetenzen zu stärken und
                  auszubauen. Neue Routinen werden durch konsequentes Üben
                  entwickelt. <br />
                  Bereits während des Trainings werden praxisnahe Fälle
                  bearbeitet und nach dem Training können Aufgaben im täglichen
                  Job trainiert werden. Fachliches Feedback und kurze
                  Reflexion-Calls, setzen Nachhaltigkeitseffekte frei.
                </Paragraph>
                <Paragraph>
                  Ich komme aus der Praxis (vielmehr bin ich weiterhin
                  mittendrin als Key Account Manager in einem harten
                  Wettbewerbsumfeld) und kann so stets aktuelle
                  Herausforderungen aus dem Unternehmensalltag einfließen
                  lassen.
                </Paragraph>
              </Relative>
            </Col>
            <Col>
              <div className="flex h-center l-h-start">
                <TransformImg
                  className="img-opacity v-img"
                  alt="Christina Pabst"
                  src={Upper}
                />
              </div>
            </Col>
          </Grid>
        </Wrapper>
      </Section>

      {/* // ---------------------------------------------------------------------------FIRST SECTION ON DESKTOP */}

      <Section className="disappear l-appear">
        <Wrapper className="flex">
          <Grid l="2" gap="7">
            <Col>
              <div className="flex h-center l-h-start">
                <TransformImg
                  className="img-opacity v-img"
                  alt="Christina Pabst"
                  src={Upper}
                />
              </div>
            </Col>
            <Col>
              <Relative>
                <h2>Training</h2>
                <h3>
                  Menschen machen Unternehmen erfolgreich und Erfolg ist nur
                  über Training erreichbar.
                </h3>
                <LetterStyle src={BackgroundLetter} />
                <Paragraph>
                  Menschen machen Unternehmen erfolgreich und Erfolg ist nur
                  über Training erreichbar. Ein Profi-Sportler trainiert nahezu
                  täglich, um an der Spitze zu bleiben. Daher ist es nur
                  logisch, Mitarbeitende regelmäßig trainieren zu lassen, um
                  ihre Kompetenzen zu stärken und auszubauen. Neue Routinen
                  werden durch konsequentes Üben entwickelt. <br />
                  Bereits während des Trainings werden praxisnahe Fälle
                  bearbeitet und nach dem Training können Aufgaben im täglichen
                  Job trainiert werden. Fachliches Feedback und kurze
                  Reflexion-Calls, setzen Nachhaltigkeitseffekte frei.
                </Paragraph>
                <Paragraph>
                  Ich komme aus der Praxis (vielmehr bin ich weiterhin
                  mittendrin als Key Account Manager in einem harten
                  Wettbewerbsumfeld) und kann so stets aktuelle
                  Herausforderungen aus dem Unternehmensalltag einfließen
                  lassen.
                </Paragraph>
              </Relative>
            </Col>
          </Grid>
        </Wrapper>
      </Section>

      {/* // ---------------------------------------------------------------------------SECOND SECTION */}

      <SecondSection className="content-visibility">
        <Wrapper className="flex">
          <Grid l="2" gap="7">
            <Col>
              <h2>Welche Trainings gibt es?</h2>
              <List>
                <li> Vertriebs- und Kommunikationskompetenz ausbauen</li>
                <li>
                  Führungsaufgaben zukunftsorientiert meistern (führen auf
                  Distanz)
                </li>
                <li> Verhandlungen selbstbewusst führen</li>
                <li>Anforderungsbezogene Trainings</li>
              </List>

              <h3>Train the Trainer</h3>
              <Paragraph>
                Sie sollen demnächst als Trainerin oder Trainer alleine vor
                einer Gruppe stehen und möchten üben, was zu tun ist, wenn es
                mal nicht so läuft wie geplant. Ich biete Ihnen praktische
                Unterstützung, damit Sie sich wohl fühlen und selbstbewusst Ihre
                Aufgabe angehen.
              </Paragraph>
              <Quote author="Franz Kafka">
                “Wege entstehen dadurch, dass man sie geht.”
              </Quote>
              <Mail />
            </Col>
            <Col>
              <div className="flex h-center l-h-start">
                <TransformImg
                  className="img-opacity v-img"
                  alt="Ein Gespräch an einem Tisch"
                  src={Lower}
                />
              </div>
            </Col>
          </Grid>
        </Wrapper>
      </SecondSection>
    </Layout>
  )
}

export default Training
